angular.module('maxfonder')
    .controller('DiskDepositRegistrationMaxfonderCtrl', function ($scope, $rootScope, $location, $filter, $state, Message, DiskService) {

        var translate = $filter('translate');

        $scope.registration = DiskService.getSavedRegistration();
        $scope.radioButtons = { taxOutsideSweden: null };
        $scope.checkboxes = { confirmCheck: false, acceptPersonalDataProcessing: false };
        $scope.depositTypes = filterDISKDepositTypes( $rootScope.config.maxfonderConfig.depositTypes );
        $scope.brokers = [];
        $scope.validationErrors = [];
        $scope.isSigningStarted = false;
        $scope.banks = [];

        $scope.documentLinks = [
            ["/ps/api/documents/AllmannaVillkorMaxMatthiessenVardepapperAB.pdf", "maxfonder_depotRegistration_link_AllmannaVillkorMMVP"],
            ["/ps/api/documents/AutogirovillkorMaxMatthiessenVardepapperAB.pdf", "maxfonder_depotRegistration_link_AutogirovillkorMMVP"],
            ["/ps/api/documents/PolicyForBastaUtforandeOchVidarebefordranAvOrder.pdf", "maxfonder_depotRegistration_link_PolicyForBastaUtforandeOchVidarebefordranAvOrder"],
            ["/ps/api/documents/IntegritetspolicyMaxMatthiessenVardepapperAB.pdf", "maxfonder_depotRegistration_link_IntegritetspolicyMMVP"],
            ["/ps/api/documents/InformationOmMaxMatthiessenVardepapper.pdf", "maxfonder_depotRegistration_link_InformationOmMaxMatthiessenVardepapper"],
            ["/ps/api/documents/InformationOmEgenskaperOchRiskerAvseendeFinansiellaInstrument.pdf", "maxfonder_depotRegistration_link_InformationOmEgenskaperOchRiskerAvseendeFinansiellaInstrument"],
            ["/ps/api/documents/RamavtalMaxMatthiessenVardepapper.pdf", "maxfonder_fundPurchase_ramavtalPaperLink"],
            ["/ps/api/documents/Information_om_diskretionart_forvaltad_portfolj_allokering.pdf", "maxfonder_depotRegistration_link_InformationOmDiskretionartForvaltadPortfolj"],
        ];
        $scope.documentLinksIsk = [
            ["/ps/api/documents/InvesteringssparkontoAllmannaVillkor.pdf", "maxfonder_depotRegistration_link_InvesteringssparkontoAllmannaVillkor"],
        ];
        $scope.documentLinksFonddepa = [
            ["/ps/api/documents/FonddepaAllmannaVillkor.pdf", "maxfonder_depotRegistration_link_FonddepaAllmannaVillkor"],
        ];

        // show error if no existing registration
        $scope.hasRegistration = $scope.registration && $scope.registration.mode;

        // load brokers
        DiskService.getBrokersAndCodes().$promise.then(function (result) {
            result.forEach(function (brokerWithCodes) {
                var name = brokerWithCodes.firstName + ' ' + brokerWithCodes.lastName;
                brokerWithCodes.brokerCodes.forEach(function (code) {
                    var brokerRow = DiskService.mapBrokerRow(name, code);
                    $scope.brokers.push(brokerRow);
                    if ((brokerWithCodes.pnr === $scope.user.brokerSsn && brokerWithCodes.brokerCodes.length === 1)) {
                        $scope.registration.broker = brokerRow;
                    }
                });
            });
        });

        // fetch banks and populate
        DiskService.getBanks().$promise.then(function (banks) {
            banks = _.sortBy(banks, 'name');
            banks.push({
                id: undefined,
                name: translate('maxfonder_depotRegistration_unknown_bank')
            });
            $scope.banks = banks;
        });

        // depositTypes
        function filterDISKDepositTypes(types) {
            return _.filter(types, function (item) {
                return item.id === 'isk' || item.id === 'fonddepa';
            });
        }

        //Sorting depositType list
        $scope.sortedDepositTypes = function () {
            return _.sortBy($scope.depositTypes.map(function (t) {
                t.name = translate('maxfonder_depotRegistration_depotType_' + t.id);
                return t;
            }), function (t) {
                return t.name;
            });
        };

        $scope.depositTypeIs = function (type) {
            return $scope.registration.depositType && $scope.registration.depositType.id === type;
        };

        $scope.closeValidateFormWarning = function () {
            if ($scope.isReady() || $scope.radioButtons.taxOutsideSweden === 'yes') {
                $scope.validationErrors = undefined;
                Message.resolveMessage('validationError');
            }
        };

        $scope.$watch('radioButtons.taxOutsideSweden', function () {
            if ('radioButtons.taxOutsideSweden' === 'yes') {
                $scope.closeValidateFormWarning();
            }
        });

        //Watches
        $scope.$watchGroup(['registration.broker',
            'checkboxes.confirmCheck',
            'registration.depositType',
            'registration.bank',
            'registration.bankAccountClearingNumber',
            'registration.bankAccountNumber'], function () {
            $scope.closeValidateFormWarning();
        });

        $scope.isReady = function () {
            return !$scope.hasValidationErrors();
        };

        $scope.validateBankAccountClearingNumber = function () {
            return DiskService.bankAccountClearingNumberCorrect($scope.registration.bankAccountClearingNumber);
        };

        $scope.validateBankAccountNumber = function () {
            return DiskService.bankAccountClearingNumberCorrect($scope.registration.bankAccountNumber);
        };

        $scope.validateAll = function () {
            $scope.validationErrors = DiskService.validateRegistrationCommon($scope.registration);
            $scope.validationErrors = $scope.validationErrors.concat(DiskService.validateDepositData($scope.registration));
            if ($scope.registration.mode !== "new") {
                $scope.validationErrors.push(DiskService.newValidationError('registrationMode'))
            }
            if (!$scope.radioButtons.taxOutsideSweden) {
                $scope.validationErrors.push(DiskService.newValidationError('taxOutsideSweden', 'missing'))
            } else if ($scope.radioButtons.taxOutsideSweden === 'yes') {
                $scope.validationErrors.push(DiskService.newValidationError('taxOutsideSweden', 'invalid'))
            }
            if (!$scope.checkboxes.confirmCheck) {
                $scope.validationErrors.push(DiskService.newValidationError('confirmCheck'))
            }
            if (!$scope.checkboxes.acceptPersonalDataProcessing) {
                $scope.validationErrors.push(DiskService.newValidationError('acceptPersonalDataProcessing'))
            }
            if (!$scope.isReady()) {
                Message.error(translate('maxfonder_disk_validationError_errTitle'), translate('maxfonder_disk_validationError_errMessage'), 'validationError');
            } else {
                Message.resolveMessage('validationError');
            }
        };

        $scope.hasValidationErrors = function () {
            return $scope.validationErrors && $scope.validationErrors.length > 0;
        };

        $scope.hasValidationErrorForField = function (fieldName, errorType=null) {
            return DiskService.getValidationErrorForField($scope.validationErrors, fieldName, errorType);
        }

        $scope.isProceedDisabled = function () {
          return $scope.radioButtons.taxOutsideSweden === 'yes';
        };

        $scope.proceed = function () {
            $scope.validateAll();
            if($scope.isReady()) {
                DiskService.saveRegistration($scope.registration);
                $state.go('maxfonderDiskPortfolioSelection');
            }
        };

        $scope.cancel = function () {
            DiskService.deleteRegistration();
            Message.resolveMessage('validationError');
            $state.go('maxfonder');
        };
});
