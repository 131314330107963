angular.module('psModule').controller('PortfoliosCtrl', function($scope) {

    $scope.insuranceCompanies = [
        {name: 'Folksam', orgNo: '502006-1585'},
        {name: 'Futur', orgNo: '516401-6643'},
        {name: 'Länsförsäkringar', orgNo: '516401-6627'},
        {name: 'Movestic', orgNo: '516401-6718'},
        {name: 'SEB', orgNo: '516401-8243'},
        {name: 'Skandia', orgNo: '516406-0948'},
        {name: 'SPP', orgNo: '516401-8599'},
        {name: 'Nordnet', orgNo: '16516406-0286'}
    ];
    $scope.useSecuritiesPortfolios = $scope.$root.config && $scope.$root.config.toggles && $scope.$root.config.toggles.USE_SECURITIES_PORTFOLIOS === true;
    $scope.activeInsuranceCompany = null;
    $scope.filterCategories = "Lifepension;Maxfonder";

});
